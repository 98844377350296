<script>
import { onMounted } from 'vue'
import { useTicketMaster } from '/~/extensions/ticket-master/composables'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'

export default {
  name: 'ticket-master',
  components: {
    BaseLoader,
  },
  setup() {
    const { widget, loading, loadScript } = useTicketMaster()
    const { page } = useEditablePage()

    onMounted(loadScript)

    return {
      page,
      widget,
      loading,
    }
  },
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-7xl flex-col">
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <base-loader v-if="loading" fullwidth class="my-5 grow" />

    <div
      v-show="!loading"
      v-if="widget.publicKey"
      :w-tmapikey="widget.publicKey"
      w-type="event-discovery"
      w-keyword=""
      w-theme="grid"
      w-colorscheme="custom"
      w-width="100%"
      w-height="100%"
      w-size="25"
      w-border="0"
      w-borderradius="0"
      w-postalcode=""
      w-radius="25"
      w-city=""
      w-period="week"
      w-layout="fullwidth"
      w-attractionid=""
      w-promoterid=""
      w-venueid=""
      w-affiliateid=""
      w-segmentid=""
      w-proportion="custom"
      w-titlelink="on"
      w-sorting="groupByName"
      w-id="id_uoa3n7"
      w-countrycode="AU"
      w-source=""
      w-branding="Ticketmaster"
      w-gridcolumns="4"
      w-showloadmorebutton="true"
      w-maxheight=""
      w-latlong=""
      w-showfulleventsnames="true"
      w-titlecolor="var(--color-primary)"
      w-datecolor="var(--color-primary)"
      w-countercolor="var(--color-primary)"
      w-bordercolor="var(--color-primary)"
      w-titlehovercolor="var(--color-primary-lighten)"
      w-buybuttonbackgroundhovercolor="var(--color-primary)"
      w-showmorebuttonbackgroundhovercolor="var(--color-primary)"
      w-loadingeventspinnercolor="var(--color-primary)"
      w-showmorebuttonbackgroundcolor="var(--color-primary)"
      w-buybuttonbackgroundcolor="var(--color-primary)"
      w-showmorebuttontextcolor="white"
      w-backgroundcolor="white"
      w-buybuttontextcolor="white"
      class="ticket-master-widget flex !h-auto grow flex-col"
    />
  </div>
</template>

<style lang="postcss">
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .legal-notice {
  @apply bottom-2.5;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .events-root-container {
  @apply flex !h-full min-h-full grow flex-col bg-transparent;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .ss-content {
  @apply right-0 !h-full min-h-full w-full grow p-0 pb-5;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .events-root {
  @apply !w-full;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .event-logo-box {
  @apply !bottom-0;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .ss-container {
  @apply flex grow flex-col;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .ss-wrapper {
  @apply flex grow flex-col pb-[45px];
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .ss-scroll {
  @apply hidden;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .events-root {
  @apply grid grid-cols-1 gap-2.5 xxs:grid-cols-2 sm:grid-cols-3 sm:gap-5 lg:grid-cols-4;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .event-wrapper {
  @apply !m-0 !w-full rounded-lg !border-none bg-light !p-0;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .bg-cover-thumbnails {
  @apply m-0;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .event-content-wraper {
  @apply p-2.5 !pb-[45px];
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .event-buy-btn,
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .show-more_button {
  @apply !flex items-center justify-center !p-0;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget .event-buy-btn {
  @apply !h-[30px] !text-xs;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .show-more_button {
  @apply col-span-full !m-0 !h-10;
}

div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .event-buy-btn:hover,
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .event-buy-btn:active,
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .event-buy-btn:focus,
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .show-more_button:hover,
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .show-more_button:active,
div[w-theme='grid'][w-layout='fullwidth'].ticket-master-widget
  .show-more_button:focus {
  @apply opacity-80;
}
</style>
